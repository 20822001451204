import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import {
  FilterDocumentCatalogue,
  InputItemExpedienteFileCar,
  ItemCatalogueExpedienteCar,
  UpdateItemExpedienteFileCar,
} from '@core/models/expediente-car.model';
import { ResponseBackAzure } from '@core/models/responsehttp.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExpedienteCarService {
  private readonly _http = inject(HttpClient);
  httpParam = new HttpParams();

  private readonly initFilter = {
    tipo_operacion: undefined,
    tipo_transaccion: undefined,
    id: undefined,
    etapa: undefined,
  };
  constructor() {
    this.httpParam = this.httpParam.append('backend', 'azure');
  }

  getCatalogue(filtros?: { tipo?: string; origen?: string; etapa?: string }) {
    const queryParams = `tipo=${filtros?.tipo ?? ''}&origen=${filtros?.origen ?? ''}&etapa=${filtros?.etapa ?? ''}`;

    return this._http.get<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/v2/mesa-control/documentos/catalogo?${queryParams}`,
      {
        params: this.httpParam,
      }
    );
  }

  postCatalogue(catalogo: ItemCatalogueExpedienteCar) {
    return this._http.post<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/v2/mesa-control/documentos/catalogo?tipo=`,
      catalogo,
      {
        params: this.httpParam,
      }
    );
  }

  getDocumentsByCarNCitaNdocumentId(autoId?: string, documentoId?: string, id_cita?: string) {
    const queryParams = `id_automovil=${autoId ?? ''}&id_documento=${documentoId ?? ''}&id_cita=${id_cita ?? ''}`;

    return this._http.get<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/v2/mesa-control/documentos?${queryParams}`,
      {
        params: this.httpParam,
      }
    );
  }

  postDocumentByCar(file: InputItemExpedienteFileCar) {
    return this._http.post<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/v2/mesa-control/documentos`, file, {
      params: this.httpParam,
    });
  }

  putDocumentByCar(file: UpdateItemExpedienteFileCar) {
    return this._http.put<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/v2/mesa-control/documentos`, file, {
      params: this.httpParam,
    });
  }
}
